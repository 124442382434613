import { h, Component, render } from 'preact';
import 'preact/devtools';
import '../styles/index.scss';

class Listening extends Component {
  constructor(props) {
    super(props);
    this.fetchLastSong = this.fetchLastSong.bind(this);
  }

  componentWillMount() {
    this.fetchLastSong().then(() => {
      this.fetchInterval = setInterval(this.fetchLastSong, 60000);
    });
  }

  fetchLastSong() {
    return fetch('https://notes.pburke.me/wp-json/last.fm/v1/status')
      .then(response => response.json())
      .then(lastSong => {
        if (lastSong) {
          this.setState({ lastSong });
        }
      });
  }

  render() {
    const { lastSong = {} } = this.state;
    const {
      '@attr': attrObj = {},
      artist: artistObj = {},
      album: albumObj = {},
      name = '',
      mbid = '',
      url = '',
      image: images = []
    } = lastSong;
    const { nowplaying: nowPlayingString } = attrObj;
    const nowPlaying = nowPlayingString === 'true';
    const { '#text': artist } = artistObj;
    // const { '#text': album } = albumObj;
    // const [ image = {} ] = images.slice(-1);
    // const { '#text': imageUrl } = image;

    return !lastSong || !nowPlaying ? null : (
      <div>
        <h2>Now playing</h2>
        <div className="now-playing">
          <div className="song">{name}</div>
          <div className="artist">{artist}</div>
        </div>
      </div>
    );
  }
}

render(<Listening />, document.getElementById('listening'));
